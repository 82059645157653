import React from "react";
import { Link as RouterLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";

import {makeStyles} from "@material-ui/core/styles";

function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  const classes = useClasses();

  return (
      <li className={classes.icon}>
          <ListItem button component={renderLink} className={className}>
              {icon ? <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> : null}
              <ListItemText className={classes.icon} primary={primary} />
          </ListItem>
      </li>
  );
}

const useClasses = makeStyles(theme => ({
    icon: {
        color: '#f3f3f3',
        "&:hover": {
            color: "#b0b0b0",
            "& .MuiListItemIcon-root": {
                color: "#b0b0b0"
            }
        }
    },
}));

const MainListItems = (props) => {
  const { drawerClose } = props;

  return (
    <div onClick={drawerClose}>
        <Divider style={{ backgroundColor: "#fcfcfc" }} />

        <ListSubheader inset style={{ color: "#e0e0e0"}}>
            <label>Clientes</label>
        </ListSubheader>

        <ListItemLink
            to="/"
            primary="Home"
            icon={<DashboardOutlinedIcon/>}
        />

        <ListItemLink
        to="/clients"
        primary="Lista de Cliente"
        icon={<ContactPhoneOutlinedIcon />}
        />

        <Divider style={{ backgroundColor: "#fcfcfc" }} />
    </div>
  );
};

export default MainListItems;
