import React, {useState, useEffect, useRef, Fragment} from "react";

import { makeStyles } from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import InputMask from "react-input-mask";
import {toast} from "react-toastify";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ClientModal = ({ open, onClose, clientId, initialValues, onSave }) => {
	const classes = useStyles();
	const isMounted = useRef(true);
	const [inputName, setInputName] = useState("");
	const [inputCnpj, setInputCnpj] = useState("");
	const [inputResponsible, setInputResponsible] = useState("");
	const [inputPhone, setInputPhone] = useState("");
	const [inputLink, setInputLink] = useState("");
	const [inputUser, setInputUser] = useState(10);
	const [inputConnection, setInputConnection] = useState(1);
	const [selectedTime, setTime] = useState(new Date());

	useEffect(() => {
		return () => {
			setTime(new Date());
			isMounted.current = false;
		};

	}, []);

	useEffect(() => {
		const fetchClient = async () => {
			if (!clientId) return;

			try {
				const { data } = await api.get(`/clients/${clientId}`);
				if (isMounted.current) {
					console.log(data)
					setInputCnpj(data.cnpj);
					setInputName(data.name);
					setInputLink(data.link);
					setInputResponsible(data.responsible);
					setInputPhone(data.phone);
					setInputUser(data.maxUser);
					setInputConnection(data.maxConnection);
					setTime(data.selledAt)
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchClient();
	}, [clientId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setInputName("");
		setInputCnpj("");
		setInputResponsible("");
		setInputPhone("");
		setInputLink("");
		setInputUser(10);
		setInputConnection(1);
	};

	const handleSaveClient = async values => {
		if (!inputName) {
			toast.error("Nome não pode estar em branco");
			return;
		}
		if (!inputCnpj) {
			toast.error("CNPJ não pode estar em branco");
			return;
		}
		if (inputUser < 1) {
			toast.error("Usuários precisa ser maior que 0");
			return;
		}
		if (inputConnection < 1) {
			toast.error("Whatsapps precisa ser maior que 0");
			return;
		}

		try {
			const clientData = {
				name: inputName,
				cnpj: inputCnpj.replaceAll("-", "").replaceAll("/", "").replaceAll(".", "").replaceAll(" ", ""),
				phone: inputPhone.replaceAll("-", "").replaceAll(" ", "").replaceAll(" ", "").replaceAll("+", ""),
				responsible: inputResponsible,
				link: inputLink,
				maxUser: inputUser,
				maxConnection: inputConnection,
				selledAt: selectedTime,
			}

			if (clientId) {
				await api.put(`/clients/${clientId}`, clientData);
				handleClose();
			} else {
				const { data } = await api.post("/clients", clientData);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			toast.success(i18n.t("contactModal.success"));
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{clientId
						? `${i18n.t("contactModal.title.edit")}`
						: `${i18n.t("contactModal.title.add")}`}
				</DialogTitle>
				<form onSubmit={handleSaveClient}>
					<DialogContent dividers>
						<TextField
							id="name"
							name="name"
							label="Nome"
							variant="outlined"
							margin="dense"
							className={classes.textField}
							onKeyPress={(e) => {
								e.key === 'Enter' && e.preventDefault();
							}}
							value={inputName}
							onChange={(e) => setInputName(e.target.value)}
						/>

						<InputMask
							mask="99.999.999/9999-99"
							maskChar=" "
							alwaysShowMask={false}
							disabled={false}
							id="cnpj"
							name="cnpj"
							className={classes.textField}
							onKeyPress={(e) => {
								e.key === 'Enter' && e.preventDefault();
							}}
							value={inputCnpj}
							onChange={(e) => setInputCnpj(e.target.value)}
						>
							{() => <TextField variant="outlined" margin="dense" label="CNPJ"/>}
						</InputMask>

						<div>
							<TextField
								id="responsible"
								name="responsible"
								label="Responsavel"
								variant="outlined"
								margin="dense"
								className={classes.textField}
								onKeyPress={(e) => {
									e.key === 'Enter' && e.preventDefault();
								}}
								value={inputResponsible}
								onChange={(e) => setInputResponsible(e.target.value)}
							/>

							<InputMask
								mask="+99 99 999999999"
								maskChar=" "
								alwaysShowMask={false}
								disabled={false}
								id="phone"
								name="phone"
								className={classes.textField}
								onKeyPress={(e) => {
									e.key === 'Enter' && e.preventDefault();
								}}
								value={inputPhone}
								onChange={(e) => setInputPhone(e.target.value)}
							>
								{() => <TextField variant="outlined" margin="dense" label="Telefone"/>}
							</InputMask>
						</div>

						<div style={{paddingRight: 7}}>
							<TextField
								id="link"
								name="link"
								label="Link"
								variant="outlined"
								margin="dense"
								className={classes.textField}
								onKeyPress={(e) => {
									e.key === 'Enter' && e.preventDefault();
								}}
								value={inputLink}
								onChange={(e) => setInputLink(e.target.value)}
								fullWidth
								placeholder="https://app.whatscontabil.com.br"
							/>
						</div>

						<div>
							<TextField
								id="maxUser"
								name="maxUser"
								label="Usuários"
								type="number"
								variant="outlined"
								margin="dense"
								className={classes.textField}
								onKeyPress={(e) => {
									e.key === 'Enter' && e.preventDefault();
								}}
								value={inputUser}
								onChange={(e) => setInputUser(e.target.value)}
							/>

							<TextField
								id="maxConnection"
								name="maxConnection"
								type="number"
								label="Whatsapps"
								variant="outlined"
								margin="dense"
								className={classes.textField}
								onKeyPress={(e) => {
									e.key === 'Enter' && e.preventDefault();
								}}
								value={inputConnection}
								onChange={(e) => setInputConnection(e.target.value)}
							/>
						</div>

						<div style={{marginTop: 7, paddingRight: 7}}>
							<Fragment>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										fullWidth
										clearable
										inputVariant="outlined"
										placeholder="15/04/2024"
										value={selectedTime}
										onChange={(date) => setTime(date)}
										// minDate={new Date()}
										format="dd/MM/yyyy"
										label="Data de venda"
										// minDateMessage={"Data não pode ser anterior a hoje"}
										// maxDateMessage={"Dia do envio"}
										invalidDateMessage={"Formato de data inválido"}
									/>
								</MuiPickersUtilsProvider>
							</Fragment>
						</div>

						{/*<Typography
							style={{marginBottom: 8, marginTop: 12}}
							variant="subtitle1"
						>
							{i18n.t("contactModal.form.extraInfo")}
						</Typography>

						<div style={{paddingRight: 7}}>
							{extraInfo &&
								extraInfo.length > 0 &&
								extraInfo.map((info, index) => (
									<div
										className={classes.extraAttr}
										key={`${index}-info`}
									>
										<TextField
											label={i18n.t("contactModal.form.extraName")}
											name={`extraInfo[${index}].name`}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<TextField
											label={i18n.t("contactModal.form.extraValue")}
											name={`extraInfo[${index}].value`}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
										<IconButton
											size="small"
											// onClick={() => remove(index)}
										>
											<DeleteOutlineIcon/>
										</IconButton>
									</div>
								))}
							<div className={classes.extraAttr}>
								<Button
									style={{flex: 1, marginTop: 8}}
									variant="outlined"
									color="primary"
									onClick={() => {
										let array = extraInfo;
										array.push({name: "", value: ""});
										setExtraInfo(array);
									}}
								>
									{`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
								</Button>
							</div>
						</div>*/}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleClose}
							color="secondary"
							// disabled={isSubmitting}
							variant="outlined"
						>
							{i18n.t("contactModal.buttons.cancel")}
						</Button>
						<Button
							type="button"
							color="primary"
							variant="contained"
							className={classes.btnWrapper}
							onClick={handleSaveClient}
						>
							{clientId
								? `${i18n.t("contactModal.buttons.okEdit")}`
								: `${i18n.t("contactModal.buttons.okAdd")}`}
							{/*{isSubmitting && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}*/}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</div>
	);
};

export default ClientModal;
