import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Login from "../pages/Login/";
import Clients from "../pages/Clients/";
import { AuthProvider } from "../context/Auth/AuthContext";
import Route from "./Route";

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
            <LoggedInLayout>
              <Route exact path="/" component={Clients} isPrivate />
              <Route exact path="/clients" component={Clients} isPrivate />
            </LoggedInLayout>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
