import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "socket.io-client";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import {MenuItem, FormControl, Select, IconButton} from "@material-ui/core";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ClientModal from "../../components/ClientModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import {format, parseISO} from "date-fns";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  mainContainer: {
    height: `calc(100% - 48px)`,
    overflowY: "hidden"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Clients = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [clients, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [sort, setSort] = useState("name");
  const [order, setOrder] = useState("ASC");
  const [countClients, setCountClients] = useState(0);
  const [status, setStatus] = useState("all");

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, sort, order, status]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchClients = async () => {
        try {
          const { data } = await api.get("/clients/", {
            params: { searchParam, pageNumber, all: true, sort, order, status },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.clients });
          setHasMore(data.hasMore);
          setCountClients(data.count);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchClients();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, sort, order, countClients, status]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("contact", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const cpfMask = (value) => {
    if (!value)
      return "";
    let splited = value.split("");
    return `${splited[0]}${splited[1]}${splited[2]}.${splited[3]}${splited[4]}${splited[5]}.${splited[6]}${splited[7]}${splited[8]}-${splited[9]}${splited[10]}`
  }

  const cnpjMask = (value) => {
    if (!value)
      return "";
    let splited = value.split("");
    return `${splited[0]}${splited[1]}.${splited[2]}${splited[3]}${splited[4]}.${splited[5]}${splited[6]}${splited[7]}/${splited[8]}${splited[9]}${splited[10]}${splited[11]}-${splited[12]}${splited[13]}`;
  }

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (clientId) => {
    try {
      await api.delete(`/clients/${clientId}`);
      toast.success("Cliente excluído com sucesso!");
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleChangeStatus = async (clientId, status) => {
    if (clientId) {
      await api.put(`/clients/${clientId}`, {
        status
      });
    }
  }

  return (
      <div className={classes.mainContainer}>
        <MainContainer>
          <ClientModal
              open={contactModalOpen}
              onClose={handleCloseContactModal}
              aria-labelledby="form-dialog-title"
              clientId={selectedContactId}>
          </ClientModal>
          <ConfirmationModal
              title="Remover esse cliente?"
              open={confirmOpen}
              onClose={setConfirmOpen}
              onConfirm={(e) =>
                  handleDeleteContact(deletingContact.id)
              }
          >
            {i18n.t("contacts.confirmationModal.deleteMessage")}
          </ConfirmationModal>
          <MainHeader>
            <Title>Clientes</Title>
            <MainHeaderButtonsWrapper>
              <span style={{color: "black"}}>Total: {countClients}</span>
              <span style={{color: "black"}}>Ordenar por:</span>
              <FormControl className={classes.formControl}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sort}
                    onChange={(e)=> setSort(e.target.value)}
                >
                  <MenuItem value="name">Nome</MenuItem>
                  <MenuItem value="selledAt">Venda</MenuItem>
                  <MenuItem value="maxUser">Usuário</MenuItem>
                  <MenuItem value="maxConnection">Whatsapp</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select
                    labelId="demo-simple-select2-label"
                    id="demo-simple2-select"
                    value={order}
                    onChange={(e)=> setOrder(e.target.value)}
                >
                  <MenuItem value="ASC">ASC</MenuItem>
                  <MenuItem value="DESC">DESC</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select
                    labelId="demo-simple-select3-label"
                    id="demo-simple3-select"
                    value={status}
                    onChange={(e)=> setStatus(e.target.value)}
                >
                  <MenuItem value="all">Todos</MenuItem>
                  <MenuItem value="ok">Liberados</MenuItem>
                  <MenuItem value="off">Bloqueados</MenuItem>
                </Select>
              </FormControl>

              <TextField
                  placeholder={i18n.t("contacts.searchPlaceholder")}
                  type="search"
                  value={searchParam}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "gray" }} />
                        </InputAdornment>
                    ),
                  }}
              />

              <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenContactModal}
              >
                Adicionar Cliente
              </Button>
            </MainHeaderButtonsWrapper>
          </MainHeader>
          <Paper
              className={classes.mainPaper}
              variant="outlined"
              onScroll={handleScroll}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <TableCell>Nome</TableCell>
                  <TableCell align="center">CNPJ</TableCell>
                  <TableCell align="center">Telefone</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Data Venda</TableCell>
                  <TableCell align="center">Link</TableCell>
                  <TableCell align="center">Users</TableCell>
                  <TableCell align="center">Whatsapps</TableCell>
                  <TableCell align="center">
                    {i18n.t("contacts.table.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {clients.map((client) => (
                      <TableRow key={client.id}>
                        <TableCell style={{ paddingRight: 0 }}></TableCell>
                        <TableCell>{client.name}</TableCell>
                        <TableCell align="center">{client.cnpj.length === 11 ? cpfMask(client.cnpj) : cnpjMask(client.cnpj)}</TableCell>
                        <TableCell align="center">{client.phone}</TableCell>
                        <TableCell align="center" style={{color: "white", backgroundColor: client.status === "OK" ? "green" : "red"}}>{client.status}</TableCell>
                        <TableCell align="center">{format(parseISO(client.selledAt), "dd/MM/yyyy")}</TableCell>
                        <TableCell align="center"><a href={client.link} rel={"noopener noreferrer"} target={"_blank"}>{client.link}</a></TableCell>
                        <TableCell align="center">{client.maxUser}</TableCell>
                        <TableCell align="center">{client.maxConnection}</TableCell>
                        <TableCell align="center">
                          <IconButton
                              size="small"
                              onClick={() => handleChangeStatus(client.id, client.status === "OK" ? "OFF" : "OK")}
                          >
                            {client.status === "OFF" ? (<LockOpenOutlinedIcon />) : (<LockOutlinedIcon />)}
                          </IconButton>
                          <IconButton
                              size="small"
                              onClick={() => hadleEditContact(client.id)}
                          >
                            <EditIcon />
                          </IconButton>
                          <Can
                              role={user.profile}
                              perform="contacts-page:deleteContact"
                              yes={() => (
                                  <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        setConfirmOpen(true);
                                        setDeletingContact(client);
                                      }}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                              )}
                          />
                        </TableCell>
                      </TableRow>
                  ))}
                  {loading && <TableRowSkeleton avatar columns={8} />}
                </>
              </TableBody>
            </Table>
          </Paper>
        </MainContainer>
      </div>
  );
};

export default Clients;
